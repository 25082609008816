/**
 * Publ. header ctrl (generally just login dialog)
 */
angular.module("public").controller("public.HeaderCtrl", ['$scope', '$http', '$window', '$location', function ($scope, $http, $window, $location) {
    var context = this;

    context.expandedNavSubmenu = "";

    addEventListener('load', loadIt, false);

    function loadIt() {

    }

    context.hideAppMenu = false;

    context.expandSubmenuClick = function (menuItem, $event) {
        if (typeof $event != 'undefined') {
            $event.preventDefault(); // do not go to the link
        }
        if (context.expandedNavSubmenu != menuItem ) {
            context.expandedNavSubmenu = menuItem;
        } else {
            context.expandedNavSubmenu = "";
        }
    };
    context.collapseAllSubmenus = function() {
        context.expandedNavSubmenu = "";
        context.navCollapsed = true; // also close nav menu
    };

    context.isSubmenuCollapsed = function (menuItem) {
        if (context.expandedNavSubmenu == menuItem) {
            return false;
        }
        return true;
    };

    context.switchLanguage = function(language, $event) {
        $window.location.href = "/change-language?lang="+language+"&uri="+$window.location.pathname;
        $event.stopPropagation();
        $event.preventDefault();
        return false;
    };


    if ($window.location.href.indexOf('activated', this.length - 'activated'.length) !== -1) {
        context.activated = true;
        context.expandSubmenuClick("login");
    }

    context.closeLoginDialog = function () {
        context.showLoginDialog = false;
    };

    context.formData = {};

    context.authenticate = function () {
        // quick workaround for autocompleted form.
        // context.formData.username = $("#username").val();
        // context.formData.password = $("#pwd").val();
        var csrfToken = $("input[name='csrfToken']").val();

        context.authPromise = $http({
            method: 'POST',
            url: '/api/users/authenticate.json',
            data: context.formData,
            headers: {
                'Csrf-Token': csrfToken
            }
        }).success(function (data) {
            context.reply = {};
            context.reply.success = data.success;
            context.reply.message = data.message;

            if (data.success) {
                context.closeLoginDialog();
                $window.location.href = '/dashboard';
            }


        });
    };

    $http({
        method: 'GET',
        url: '../api/users/get-current-user.json',
        cache: false,
        params: {notFromCache: true}
    }).then(function (data) {
        $scope.loggedUser = data.data.item;
    });

}]);

/**
 * Publ. footer ctrl
 */
angular.module("public").controller("public.FooterCtrl", ['$scope', '$http', '$window', function ($scope, $http, $window) {
    var context = this;
    context.dataPolicySubmenu = false;
    context.changeLang = function (lang) {
        $http({
            method: 'GET',
            url: '/api/users/change-language.json?lang=' + lang
        }).success(function (data) {
            $window.location.reload();
        });
    };
    context.showDataPolicySubmenu = function () {
        context.dataPolicySubmenu = ! context.dataPolicySubmenu;
    };
}]);

/**
 * First (freelance) login
 */
angular.module("public").controller("public.firstLoginCtrl", ['$scope', '$http', '$location', function ($scope, $http, $location) {
    $scope.currentForm = "loginForm";
    $scope.showForm = function (val) {
        $scope.currentForm = val;
    };

    $scope.passwordForgottonForm = {};
    $scope.passwordForgottonReply = {};
    $scope.passwordForgottonFormErrors = {};
    $scope.submitPasswordForgottonForm = function () {
        if ($scope.passwordForgottonForm && typeof $scope.passwordForgottonForm.email != 'undefined' && $scope.passwordForgottonForm.email.length > 0) {
            $http({
                method: "POST",
                url: "/api/users/password-forgotton.json",
                data: $scope.passwordForgottonForm
            }).then(function (data) {
                var response = data.data;
                if (response.success) {
                    $scope.passwordForgottonReply = response;
                }
                else {
                    $scope.passwordForgottonFormErrors = response.validationErrors;
                    $scope.passwordForgottonFormReply = response;
                }
            });
        }
    };
}]);

angular.module("public").controller("public.LoginCtrl", ['$scope', '$http', '$location', '$window', function ($scope, $http, $location, $window) {
    $scope.formData = {};
    $scope.authenticate = function () {
        var csrfToken = $("input[name='csrfToken']").val();

        $scope.authPromise = $http({
            method: 'POST',
            url: '/api/users/authenticate.json',
            data: $scope.formData,
            headers: {
                'Csrf-Token': csrfToken
            }
        }).success(function (data) {
            $scope.reply = {};
            $scope.reply.success = data.success;
            $scope.reply.message = data.message;

            if (data.success) {
                if (data.redirectToSelfServicePostLoginPage){
                    $window.location.href = '/users/self-service-post-login';
                }else{
                    $window.location.href = '/dashboard';
                }
            }
        });
    };

}]);

/**
 * Register form
 */
angular.module("public").controller("public.RegisterCtrl", ['$scope', '$http', '$location', function ($scope, $http, $location) {

    // Registration form
    $scope.registrationResponse = {};
    $scope.registerFormData = {};
    //default registration type is provider (Berater)
    $scope.registerFormUserType = {corporate: false, provider: false, child_user: false};

    $scope.registerFormData.generalForm = true;

    $scope.registerFormUpdateUserType = function (type) {
        if (type == 'corporate') {
            $scope.registerFormData.type = type;
            $scope.registerFormUserType.corporate = true;
            $scope.registerFormUserType.provider = false;
            $scope.registerFormUserType.child_user = false;
        }
        else if (type == 'provider') {
            $scope.registerFormData.type = type;
            $scope.registerFormUserType.corporate = false;
            $scope.registerFormUserType.provider = true;
            $scope.registerFormUserType.child_user = false;
        }
        else if (type == 'child_user') {
            $scope.registerFormData.type = type;
            $scope.registerFormUserType.corporate = false;
            $scope.registerFormUserType.provider = false;
            $scope.registerFormUserType.child_user = true;
        }
    };

    $scope.initRegistrationParams = function () {
        if (typeof $location.search().register != 'undefined' && $location.search().register) {
            $scope.registerFormData.email = $location.search().register;

            var type = $location.search().type;
            if (type != 'undefined' && type) {
                $scope.registerFormUpdateUserType(type);
            }
        }

        if (!angular.isDefined($scope.registerFormData.type) || $scope.registerFormData.type === null) {
            //service provider by default
            $scope.registerFormUpdateUserType('provider');
        }
    };
    $scope.initRegistrationParams();
    $scope.prefillPhoneNumber = function (){

        if (!$scope.registerFormData.phone) {
            $scope.registerFormData.phone = '+44';
        }
    };

    $scope.submitRegistrationForm = function () {
        if (!$scope.registerFormData.terms) {
            $scope.registerFormData.terms = null;
        }
        if (!$scope.registerFormData.dataSecurity) {
            $scope.registerFormData.dataSecurity = null;
        }
        if (!$scope.registerFormData.newsletter) {
            $scope.registerFormData.newsletter = null;
        }
        if ($scope.registerFormData.termsAndDataSecurity) { //one checkbox for both
            $scope.registerFormData.terms = true;
            $scope.registerFormData.dataSecurity = true;
        }

        $scope.registrationPromise = $http.post('/api/users/register.json', $scope.registerFormData).success(function (response) {
            if (!response.success) {
                if (response.userExists) {
                    window.location = "firstlogin?email=" + $scope.registerFormData.email;
                    return;
                }
                else {
                    $scope.registerFormErrors = response.validationErrors;
                    return;
                }
            } else {
                $scope.registerFormErrors = [];
                $scope.registrationResponse = response;
                $scope.registerFormData = {};
                // GTM implementation
                if (typeof dataLayer != 'undefined') {
                    if ($scope.registerFormUserType.provider) {
                        dataLayer.push({
                            'event': 'registration',
                            'category': 'freelancer',
                            'status': 'initiateRegistration'
                        });
                    } else if ($scope.registerFormUserType.corporate) {
                        dataLayer.push({
                            'event': 'registration',
                            'category': 'company',
                            'status': 'initiateRegistration'
                        });
                    }
                }
            }
        });
    };
}]);

angular.module("public").controller("public.RegisterConsultantCtrl", ['$scope', '$http', '$location', '$controller', function ($scope, $http, $location, $controller) {

    angular.extend(this, $controller('public.RegisterCtrl', {$scope: $scope}));

    $scope.registerFormData.generalForm = false;
    $scope.registerFormData.type = 'provider';

    $scope.registerFormUserType.corporate = false;
    $scope.registerFormUserType.child_user = false;

    $scope.registerFormUserType.provider = true;
}]);

angular.module("public").controller("public.RegisterCompanyCtrl", ['$scope', '$http', '$location', '$controller', function ($scope, $http, $location, $controller) {

    angular.extend(this, $controller('public.RegisterCtrl', {$scope: $scope}));

    $scope.registerFormData.generalForm = false;
    $scope.registerFormData.type = 'corporate';

    $scope.registerFormUserType.provider = false;
    $scope.registerFormUserType.child_user = false;

    $scope.registerFormUserType.corporate = true;
}]);

angular.module("public").controller("public.FaqCtrl", ['$scope', function ($scope) {
    $scope.questions=[false];

    $scope.showHideResponse = function(number) {
        $scope.questions[number] = !$scope.questions[number];
    };
}]);

angular.module("public").controller("public.ForgotPasswordCtrl", ['$scope', '$http', '$location', function ($scope, $http, $location) {
    $scope.passwordForgottonForm = {};
    $scope.passwordForgottonReply = {};
    $scope.passwordForgottonFormErrors = {};
    $scope.submitPasswordForgottonForm = function () {
        if (typeof $scope.passwordForgottonForm.email != 'undefined' && $scope.passwordForgottonForm.email.length > 0) {
            $http({
                method: "POST",
                url: "/api/users/request-new-password.json",
                data: $scope.passwordForgottonForm
            }).then(function (data) {
                $scope.passwordForgottonReply = {};
                $scope.passwordForgottonFormErrors = {};

                var response = data.data;
                if (response.success) {
                    $scope.passwordForgottonReply = response;
                }
                else {
                    $scope.passwordForgottonFormErrors = response.validationErrors;
                    $scope.passwordForgottonFormReply = response;
                }
            });
        }
    };
}]);

angular.module("public").controller("public.SearchAgentDeactivationCtrl", ['$scope', '$http', '$window', function ($scope, $http, $window) {

    $scope.searchAgentDeactivated = false;

    var parseLocation = function (location) {
        var pairs = location.substring(1).split("&");
        var obj = {};
        var pair;
        var i;
        for (i in pairs) {
            if (pairs[i] === "") continue;
            pair = pairs[i].split("=");
            obj[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
        }
        return obj;
    };
    var urlParams = parseLocation(window.location.search);

    $scope.searchAgentDeactivate = function () {
            $http({
                method: "POST",
                url: "/api/searchagent/deactivate-search-agent.json",
                params: {id: urlParams.id, token: urlParams.token}
            }).then(function (data) {
                var response = data.data;
                if (response.success) {
                    $scope.searchAgentDeactivated = true;
                }
                else {
                    $scope.searchAgentDeactivated = false;
                }
                $scope.searchAgentDeactivationMessage = response.message;
            });
    };

    $scope.searchAgentDoNotDeactivate = function () {
        $window.location.href = "/";
    };
}]);

angular.module("public").controller("public.ForgotPasswordResetCtrl", ['$scope', '$http', '$location', function ($scope, $http, $location) {
    $scope.forgotPasswordResetForm = {};
    $scope.forgotPasswordResetReply = {};
    $scope.forgotPasswordResetFormErrors = {};


    var parseLocation = function (location) {
        var pairs = location.substring(1).split("&");
        var obj = {};
        var pair;
        var i;

        for (i in pairs) {
            if (pairs[i] === "") continue;

            pair = pairs[i].split("=");
            obj[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
        }

        return obj;
    };

    var urlParams = parseLocation(window.location.search);
    $scope.submitPasswordResetForm = function () {
        if ($scope.forgotPasswordResetForm.password && $scope.forgotPasswordResetForm.password.length > 0) {
            $http({
                method: "POST",
                url: "/api/users/forgot-password-reset.json",
                params: {id: urlParams.id, token: urlParams.token},
                data: $scope.forgotPasswordResetForm
            }).then(function (data) {
                $scope.forgotPasswordResetReply = {};
                $scope.forgotPasswordResetFormErrors = {};

                var response = data.data;
                if (response.success) {
                    $scope.forgotPasswordResetReply = response;
                }
                else {
                    $scope.forgotPasswordResetFormErrors = response.validationErrors;
                    $scope.forgotPasswordResetReply = response;
                }
            });
        }
    };
}]);


/**
 * Index customers ctrl
 */
angular.module("public").controller("public.IndexForCorporationCtrl", ['$scope', '$http', function ($scope, $http) {
    // Registration form
    $scope.registrationResponse = {};
    $scope.registerFormData = {
        type: 'corporate',
        email: '',
        password: ''
    };
    $scope.submitRegistrationForm = function () {
        if (!$scope.registerFormData.terms) {
            $scope.registerFormData.terms = null;
        }
        if ($scope.registerFormData.termsAndDataSecurity) { //one checkbox for both
            $scope.registerFormData.terms = true;
            $scope.registerFormData.dataSecurity = true;
        }
        $scope.registrationPromise = $http.post('/api/users/register.json', $scope.registerFormData).success(function (response) {
            if (!response.success) {
                $scope.registerFormErrors = response.validationErrors;
            } else {
                $scope.registerFormErrors = [];
                $scope.registrationResponse = response;
                $scope.registerFormData = {};
            }
        });
    };
}]);


/**
 * Index provider ctrl
 */
angular.module("public").controller("public.IndexForProviderCtrl", ['$scope', '$http', function ($scope, $http) {
    // Registration form
    $scope.registrationResponse = {};
    $scope.registerFormData = {
        type: 'provider',
        email: '',
        password: ''
    };

    $scope.submitRegistrationForm = function () {
        if (!$scope.registerFormData.terms) {
            $scope.registerFormData.terms = null;
        }
        if ($scope.registerFormData.termsAndDataSecurity) { //one checkbox for both
            $scope.registerFormData.terms = true;
            $scope.registerFormData.dataSecurity = true;
        }
        $scope.registrationPromise = $http.post('/api/users/register.json', $scope.registerFormData).success(function (response) {
            if (!response.success) {
                $scope.registerFormErrors = response.validationErrors;
            } else {
                $scope.registerFormErrors = [];
                $scope.registrationResponse = response;
                $scope.registerFormData = {};
            }
        });
    };
}]);

/**
 * @upgrade note, when rewriting this to new angular, all 4 controllers subscriptions/public CompanyPricelistCtrl, FreelancerPricelistCtrl
 * are basically the same now.
 */
angular.module("public").controller("public.SubscriptionCompanyPricelistCtrl", ['$http', function ($http) {
    let context = this;
    context.subscriptionTypes = [];

    context.showPricesWithVat = false;
    context.allowShowPricesWithVat = false;

    context.availableSubscriptionDurations = []; // we will build that from the prices of the first subscriptionType
    context.selectedDurationMonths = null;

    context.setSubscriptionsDuration = function (durationMonths) {
        context.selectedDurationMonths = durationMonths;
    }
    context.getSubscriptionTypePrice = function (subscriptionType) {
        if (subscriptionType.prices !== null && subscriptionType.prices.length > 0) {
            for (const price of subscriptionType.prices) {
                if (price.durationMonths == context.selectedDurationMonths) {
                    return price;
                }
            }
            return subscriptionType.prices[0];
        }
        else {
            return null;
        }
    }
    context.getSubscriptionHasDiscount = function (subscriptionType) {
        let price = context.getSubscriptionTypePrice(subscriptionType);
        return price != null && price.discountedPrice != null;
    }

    let promise = $http({
        method: 'GET',
        url: "/api/subscriptions/get-subscription-types.json",
        params: {accountType: 'corporation'},
        cache: false
    }).then(function (data) {
        if (data.data.success) {
            return data.data;
        } else {
            return null;
        }
    });
    promise.then(function (response) {
        if (response && response.success) {
            context.subscriptionTypes = response.items;

            context.allowShowPricesWithVat = response.items.at(-1).prices.at(-1).vat > 0;
            for (const price of context.subscriptionTypes.at(-1).prices) {
                context.availableSubscriptionDurations.push({
                    durationDescription: price.durationDescription,
                    durationMonths: price.durationMonths
                });
            }
            context.selectedDurationMonths = context.availableSubscriptionDurations.at(-1).durationMonths;
        }
    });

}]);

angular.module("public").controller("public.SubscriptionFreelancerPricelistCtrl", ['$http', function ($http) {
    var context = this;
    context.selectedPrice = null;
    context.subscriptionTypes = [];

    context.showPricesWithVat = false;
    context.allowShowPricesWithVat = false;

    context.availableSubscriptionDurations = []; // we will build that from the prices of the first subscriptionType
    context.selectedDurationMonths = null;

    context.setSubscriptionsDuration = function (durationMonths) {
        context.selectedDurationMonths = durationMonths;
    }
    context.getSubscriptionTypePrice = function (subscriptionType) {
        if (subscriptionType.prices !== null && subscriptionType.prices.length > 0) {
            for (const price of subscriptionType.prices) {
                if (price.durationMonths == context.selectedDurationMonths) {
                    return price;
                }
            }
            return subscriptionType.prices[0];
        }
        else {
            return null;
        }
    }
    context.getSubscriptionHasDiscount = function (subscriptionType) {
        let price = context.getSubscriptionTypePrice(subscriptionType);
        return price != null && price.discountedPrice != null;
    }

    let promise = $http({
        method: 'GET',
        url: "/api/subscriptions/get-subscription-types.json",
        params: {accountType: 'serviceProvider'},
        cache: false
    }).then(function (data) {
        if (data.data.success) {
            return data.data;
        } else {
            return null;
        }
    });
    promise.then(function (response) {
        if (response && response.success) {
            context.subscriptionTypes = response.items;
            context.allowShowPricesWithVat = response.items.at(-1).prices.at(-1).vat > 0;
            for (const price of context.subscriptionTypes.at(-1).prices) {
                context.availableSubscriptionDurations.push({
                    durationDescription: price.durationDescription,
                    durationMonths: price.durationMonths
                });
            }
            context.selectedDurationMonths = context.availableSubscriptionDurations.at(-1).durationMonths;
        }
    });

}]);

angular.module("public").controller("public.ContactFormCtrl", ['$scope', '$http', function ($scope, $http) {
    $scope.contactForm = {};
    $scope.contactFormErrors = {};
    $scope.contactFormResponse = null;

    $scope.sendContactForm = function () {
        $scope.contactFormErrors = {};
        $http.post('/api/application/send-contact-form.json', $scope.contactForm).success(function (response) {
            $scope.contactFormResponse = response;
            if (response.success) {
                $scope.contactForm = {};
                // GTM implementation
                if (typeof dataLayer != 'undefined') {
                    dataLayer.push ({'event':'contactform','action':'submit'});
                }
            }
            else if (!response.success && angular.isDefined(response.validationErrors)) {
                $scope.contactFormErrors = response.validationErrors;
            }
        });
    };
}]);

angular.module("public").controller("public.UpdateFreelancerAvailabilityCtrl", ['$scope', '$http', '$location', function ($scope, $http, $location) {
   var context = this;
   context.id = null;
   context.uuid = null;
   context.formData = {availability: 'nextdate', availabilityNextDate: null};
   context.finished = $location.absUrl().slice($location.absUrl().length - 8) != 'nextdate';

    // datepicker
    context.availabilityDatepickerOpened = false;
    context.toggleAvailabilityDatepicker = function ($event) {
        $event.preventDefault();
        $event.stopPropagation();
        context.availabilityDatepickerOpened ^= true;
    };

    context.updateAvailability = function () {
        var url = '/api/resources/update-availability.json?id=' + context.id + "&uuid=" + context.uuid;
        $http.post(url, context.formData).success(function (response) {
            if (response.success) {
                context.finished = true;
            }
            else if (!response.success && angular.isDefined(response.message)) {
                context.errorMessage = response.message;
            }
        });
    }
}]);
